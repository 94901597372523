import {
  WsBrokerLoginType,
  EndpointTransports,
  IActionResultValues,
  IEndPoints,
  IExtendedEndPoint,
  IResourceType,
  LoginTypes,
} from '@core/wsbroker/types';
import {
  IMaxibonContext,
  IPcoIPContext,
  IPcoIPWebContext,
} from '@bridge/types/SessionTypes';
import { ILogger } from '@bridge/ILogger';
import { ClientError } from '@core/error/ClientError';
import { ClientErrorCode } from '@bridge/types/ErrorTypes';
import { sessionManager } from '@/stores/session';

export class SessionContextGenerator {
  private readonly logger: ILogger;
  private static readonly EMPTY_SERVER_URL = ' ';
  private static readonly EMPTY_EXTENSION_DATA = ' ';

  constructor(logger: ILogger) {
    this.logger = logger;
  }

  generateMaxibonSessionContext(
    resultValues: IActionResultValues,
    resourceType: IResourceType
  ): IMaxibonContext {
    const authToken = this.generateAuthTokenForMaxibonSession(
      resultValues,
      resourceType
    );

    let extendedDcvEndpointsString;
    if (resourceType === IResourceType.WORKSPACE) {
      if (resultValues?.extendedDcvEndpoints) {
        this.logger.info(
          `Parsing extended endpoint gateway information for resource type ${resourceType}`
        );
        const extendedDcvEndpointsJson = JSON.parse(
          resultValues.extendedDcvEndpoints
        ) as IExtendedEndPoint[];
        extendedDcvEndpointsString = this.parseMaxibonExtendedEndpoints(
          extendedDcvEndpointsJson
        );
      } else {
        this.logger.error(
          `Invalid gateway information received for resourceType ${resourceType}. Unable to proceed to streaming.`
        );
        throw new ClientError(
          ClientErrorCode.AllocateResourceUnsupportedTransportType,
          `ExtendedGateway endpoints not present`
        );
      }
    }

    let serverUrl = SessionContextGenerator.EMPTY_SERVER_URL;
    let extensionData = SessionContextGenerator.EMPTY_EXTENSION_DATA;
    if (resourceType === IResourceType.WORKSPACE_POOL) {
      this.logger.info(
        `Parsing targeted endpoint gateway information for resource type ${resourceType}`
      );
      const dcvEndPointsJson = JSON.parse(
        resultValues.dcvEndpoints
      ) as IEndPoints[];
      const parsedEndpoints = this.parseMaxibonEndpoints(dcvEndPointsJson);
      serverUrl = parsedEndpoints.serverUrl;
      extensionData = parsedEndpoints.extensionData;
    }

    const maxibonSessionContext = {
      SessionId: resultValues.sessionId,
      AuthToken: authToken,
      ExtendedServerUrls: extendedDcvEndpointsString,
      ServerUrl: serverUrl,
      ExtensionData: extensionData,
    };

    return maxibonSessionContext;
  }

  generatePcoIPWebSessionContext(
    resultValues: IActionResultValues
  ): IPcoIPWebContext {
    this.logger.info(`Generating PcoIP web session context`);
    const gateways = resultValues.gatewaysConnectionInformation;
    let stxHdCredentials = JSON.parse(gateways).clientVariables
      .WEBRTC_SESSION_INFO as string;

    if (stxHdCredentials?.includes('signalingConfiguration')) {
      stxHdCredentials = stxHdCredentials.replace(
        'signalingConfiguration',
        'SignalingConfiguration'
      );
    }
    if (stxHdCredentials?.includes('iceConfiguration')) {
      stxHdCredentials = stxHdCredentials.replace(
        'iceConfiguration',
        'IceConfiguration'
      );
    }

    return {
      StxHDCredentials: JSON.parse(stxHdCredentials),
    };
  }

  generatePcoIPSessionContext(
    resultValues: IActionResultValues
  ): IPcoIPContext {
    this.logger.info(`Generating PcoIP native session context`);
    const gateways = resultValues.gatewaysConnectionInformation;

    return {
      SsgGateways: JSON.parse(gateways),
    };
  }

  private generateAuthTokenForMaxibonSession(
    resultValues: IActionResultValues,
    resourceType: IResourceType
  ) {
    let authBlob, authToken;
    const getServiceCapability = sessionManager.get('primaryAuthMethod');
    const serviceCapabilityValue =
      getServiceCapability?.ServiceCapabilities?.CredSource;

    // For Pwd ReArch workflow we obtain encoded authBlob from Session Storage as we no longer receive in PerformResourceAction response.
    if (serviceCapabilityValue === 'CLIENT') {
      this.logger.info(
        `Retrieving AuthBlob value from Session Storage for Broker Protocol V2 capability`
      );
      authBlob = sessionManager.get('authBlob');
      if (!authBlob) {
        this.logger.warn(
          'AuthBlob is not available in both Session Storage and response result values'
        );
      }
      this.logger.info(
        `Generating WSP 2.0 BROKER_PROTOCOL_V2 streaming session auth details for resource type ${resourceType}`
      );
      authToken = authBlob;
    } else {
      authBlob = resultValues.authBlob;
      // We don't need to format the authBlob when it's WS Pool even for CBA
      if (
        resultValues.loginType ===
          WsBrokerLoginType.VirtualSmartCardCertificate &&
        resourceType !== IResourceType.WORKSPACE_POOL
      ) {
        authBlob = this.createAuthBlobForSeamlessLogin(authBlob);
      }
      this.logger.info(`Generating up WSP 2.0 session context`);

      // NPD resources [WORKSPACE_POOL] authBlob format is different and should not be encoded using btoa.
      if (resourceType === IResourceType.WORKSPACE_POOL) {
        this.logger.info(
          `Skipping generating encoded streaming session auth details for resource type ${resourceType}`
        );
        authToken = authBlob;
      } else {
        this.logger.info(
          `Generating encoded streaming session auth details for resource type ${resourceType}`
        );
        authToken = btoa(authBlob);
      }
    }
    return authToken;
  }

  private createAuthBlobForSeamlessLogin(authBlob: string) {
    const certBlob = JSON.parse(authBlob);

    return JSON.stringify({
      certBlob,
      loginType: LoginTypes.SEAMLESS,
      highlanderPCACert: '.',
      highlanderPCACRL: '.',
      keyBlob: '',
    });
  }

  private parseMaxibonExtendedEndpoints(
    extendedDcvEndpointsJson: IExtendedEndPoint[]
  ) {
    const extendedEndPoints = extendedDcvEndpointsJson.map(
      (extendedDcvEndpoint: IExtendedEndPoint) => {
        const port = extendedDcvEndpoint.port;
        const transport = extendedDcvEndpoint.transport;
        const gatewayAuthToken = extendedDcvEndpoint.gatewayAuthToken;
        const url = new URL(`https://${extendedDcvEndpoint.hostname}`);
        url.port = port;
        return {
          transport,
          url,
          gatewayAuthToken,
        };
      }
    );
    return JSON.stringify(extendedEndPoints);
  }

  private parseMaxibonEndpoints(dcvEndPointsJson: IEndPoints[]) {
    // Web Client only supports TCP endpoint
    const tcpEndPoint =
      dcvEndPointsJson.find(
        (dcvEndpoint) => dcvEndpoint.transport === EndpointTransports.TCP
      ) ?? dcvEndPointsJson[0];

    // if the fallback endpoint is not TCP (e.g. QUIC), we should raise up proper error since we don't support it
    if (
      tcpEndPoint.transport &&
      tcpEndPoint.transport !== EndpointTransports.TCP
    ) {
      this.logger.error(
        `Unsupported transport type Received for endpoint for resourceType Pools`
      );
      throw new ClientError(
        ClientErrorCode.AllocateResourceUnsupportedTransportType,
        `TCP endpoint not found in brokerResponse for resourceType Pools`
      );
    }

    const port = tcpEndPoint.port;
    const url = new URL(`https://${tcpEndPoint.url}`);

    // For Non Persistent Desktop scenarios, we will not get port information, since we connect to AppStream DCV [WSP] endpoint
    url.port = port;
    const serverUrl = `${url.origin}${url.pathname}`;

    // For NPD scenarios, we will not get extension data but will need to set to non empty value to ensure in-session SDK wont throw error
    const extensionData = url.searchParams.get('gatewayToken') ?? ' ';

    return { serverUrl, extensionData };
  }
}
